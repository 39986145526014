import React from 'react';

const chatClients =()=>{
    return(
        <section className="chat_get_started_area">
            <div className="container">
                <div className="chat_get_started_content text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">Und, wie lässt sich die Lösung integrieren?</h2>
                    <p>
                        Lassen Sie uns darüber reden! Wir finden bestimmt einen Weg, wie wir den 
                        Kampagnen-Builder optimal in Ihre Prozesse einbinden können. 
                    </p>
                </div>
            </div>
        </section>
    )
}
export default chatClients;
