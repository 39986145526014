import React, { Component } from "react";

class ChatFeatures1 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section id="section_service" className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
              
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/video.png")}
                  alt=""
                />

            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <img src={require("../../img/home-chat/video_icon.png")} alt="" />
                <h2>
                  <span>Videos</span> erstellen
                </h2>
                <p>
                    Produzieren Sie so viele Videos, wie Sie für Ihre Kampagne benötigen!
                </p>
                <p>
                    Mit dem <strong>Template-Builder</strong> einfach Vorlagen für Text oder 
                    Videoeinblendungen designen. Einmal erstellen, immer wieder anwenden!
                </p>
                <p>
                    <strong>Multiformate:</strong> Aus 1 mach 4! Aus Querformat werden 
                    Videos in 4 Formaten erstellt. Damit Sie für alle Sozialen-Medien 
                    optimal aufgestellt sind.
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures1;
