import React from 'react';

const ImpressSection =()=>{
    return(
        <section className="faq_area bg_color sec_pad">
            <div className="container">
                <div className="row"> 
                    <div className="col-lg-12">
                        <div className="tab-content">

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Betreiber / Inhaber dieser Website</h3>
                                <p>joobster gmbh<br/>Zentastraße 6/3/9<br/>AT – 4061 Pasching<br/>mobil: +43 699 18 24 20 58<br/>email: hello@joobster.at</p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Konzeption, Design, Realisierung und Programmierung</h3>
                                <p>joobster gmbh<br/>Zentastraße 6/3/9<br/>AT- 4061 Pasching<br/>www.joobster.at</p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Rechtliche Hinweise</h3>
                                <p><a href="https://www.joobster.at/agb/">Unsere Geschäftsbedingungen – AGB</a></p>
                                <p>
                                Sämtliche Informationen und Urheber Rechte dieser Homepage, sind ausdrücklich Eigentum von joobster und unterliegen dem Urheberrecht. Diese Informationen dürfen nur für den persönlichen Gebrauch genutzt werden. Die Informationen dürfen ohne die vorherige schriftliche Zustimmung von joobster auf keine andere Weise kopiert, verbreitet oder in anderen Dokumenten bzw. in elektronischer Form verwendet werden, außer joobster stellt diese Informationen ausdrücklich zur freien weiteren Verwendung (z.B. Download) zur Verfügung.
                                Die Informationen auf dieser Homepage werden nach bestem Wissen zur Verfügung gestellt und entstammen Quellen, die für verlässlich gehalten werden. Joobster haftet nicht für die Genauigkeit der Informationen auf dieser Homepage oder für deren Vollständigkeit, und aus ihnen können keinerlei Ansprüche hergeleitet werden. Insbesondere übernimmt joobster keine Haftung für Informationen, die mit einem Verweis auf den Urheber dieser Informationen versehen sind, und übernimmt keine Haftung für allfällige Schäden, die aus der Verwendung von Informationen auf dieser Homepage oder auf Homepages, auf die von der Homepage von joobster verwiesen wird, entstehen.
                                Joobster ist der Urheber von Homepages, auf die von der Homepage www.joobster.at verwiesen wird, und übernimmt folglich auch keinerlei Verantwortung für den Inhalt und den Gebrauch von Informationen solcher Homepages.
                                </p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Nutzungsbedingungen</h3>
                                <p>
                                Die Texte und Fotos auf den Seiten (inklusive Quelltext und Software), die auf dieses Impressum verweisen, sind urheberrechtlich geschützt. Das Kopieren dieser Dateien und ihre Veränderung sind daher ohne Genehmigung des Urhebers (joobster) nicht gestattet.
                                </p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Haftungsausschluss</h3>
                                <p>
                                Joobster übernimmt keine Verantwortung für die Inhalte, Fehlerfreiheit, Rechtmäßigkeit und Funktionsfähigkeit von Internet-Seiten Dritter, auf welche auf den www.joobster-Seiten verwiesen wird (Links). Bitte wenden Sie sich an den zuständigen Informationsanbieter.
                                </p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Haftung für Links</h3>
                                <p>
                                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                                </p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Urheberrecht</h3>
                                <p>
                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem österreichischen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                                </p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Datenschutz</h3>
                                <p>
                                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                                </p>
                                <h4>Datenschutzerklärung für die Nutzung von Facebook-Plugins (Like-Button)</h4>
                                <p>
                                Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem “Like-Button” (“Gefällt mir”) auf unserer Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier: http://developers.facebook.com/docs/plugins/. Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook “Like-Button” anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von facebook unter http://de-de.facebook.com/policy.php
                                Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
                                </p>
                                <h4>Datenschutzerklärung für die Nutzung von Google +1</h4>
                                <p>
                                Erfassung und Weitergabe von Informationen: Mithilfe der Google +1-Schaltfläche können Sie Informationen weltweit veröffentlichen. über die Google +1-Schaltfläche erhalten Sie und andere Nutzer personalisierte Inhalte von Google und unseren Partnern. Google speichert sowohl die Information, dass Sie für einen Inhalt +1 gegeben haben, als auch Informationen über die Seite, die Sie beim Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen auf Websites und Anzeigen im Internet eingeblendet werden. Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die Google-Dienste für Sie und andere zu verbessern. Um die Google +1-Schaltfläche verwenden zu können, benötigen Sie ein weltweit sichtbares, öffentliches Google-Profil, das zumindest den für das Profil gewählten Namen enthalten muss. Dieser Name wird in allen Google-Diensten verwendet. In manchen Fällen kann dieser Name auch einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten über Ihr Google-Konto verwendet haben. Die Identität Ihres Google-Profils kann Nutzern angezeigt werden, die Ihre E-Mail-Adresse kennen oder über andere identifizierende Informationen von Ihnen verfügen. Verwendung der erfassten Informationen: Neben den oben erläuterten Verwendungszwecken werden die von Ihnen bereitgestellten Informationen gemäß den geltenden Google-Datenschutzbestimmungen genutzt. Google veröffentlicht möglicherweise zusammengefasste Statistiken über die +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner weiter, wie etwa Publisher, Inserenten oder verbundene Websites.
                                </p>
                                <p>
                                Bei Fragen wenden Sie sich bitte an:<br/>
                                joobster | 4061 Pasching – Österreich | www.joobster.at | hello@joobster.at | Telefon +43 699 18 24 20 58
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ImpressSection;