import React from "react";

const CoreFeatures = () => {
  return (
    <section id="section_core" className="chat_core_features_area sec_pad">
      <div className="container">
        <div className="hosting_title chat_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            Warum wir auf Video setzen?<br /> 
            <span>Weil Imagebildung genau so funktioniert!</span>
          </h2>
        </div>
        <div className="row">

          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
              <div className="round">
                <div className="round_circle two"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/triangle.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/mobile.png')} alt="" />
              </div>
              <h4>Video-Builder</h4>
              <p>
                Erstellen Sie mit eigenen Vorlagen einfach ansprechende HR-Videos,  
                optimiert für die Sozialen-Medien (Formate 16x9, 1x1, 4x5, 9x16).  
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp">
              <div className="round">
                <div className="round_circle"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/pluse.png")}
                  alt=""
                />
                <img src={require("../../img/home-chat/chat.png")} alt="" />
              </div>
              
              <h4>Interview-Builder</h4>
              <p>
                Erstellen Sie so viele WhatsApp-Interviews mit Video- und Bildeinblendungen 
                wie Sie benötigen. Zeigen Sie sich von Ihrer besten Seite!
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.4s">
              <div className="round">
                <div className="round_circle three"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/box.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/book.png')} alt="" />
              </div>
              
              <h4>Verwaltungstool</h4>
              <p>
                Im Admin-Tool haben Sie alle Interviews im Überblick, und können die Bewerbungen verwalten. Einfacher geht's nicht!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoreFeatures;
