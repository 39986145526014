import React, { Component } from "react";

class ChatFeatures2 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/whatsapp.png")}
                  alt="" />
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <img src={require("../../img/home-chat/chat_icon.png")} alt="" />
                <h2>
                  <span>WhatsApp</span> Interviews
                </h2>
                <p>
                    Interviews erstellen, und mit Videos verknüpfen! Videos schaffen 
                    Vertrauen, und erhöhen die Abschlussquote. 
                </p>
                <p>
                    Für jedes Interview wird ein <strong>QR-Code</strong> und ein 
                    <strong>WhatsApp-Link</strong> erstellt. Verwenden Sie den QR-Code 
                    auch in analogen Kampagnen, wie z.B. auf Plakaten, oder Schaltungen in Printmedien. 
                </p>
                <p>
                    Der WhatsApp-Link führt Bewerber von Webseiten oder Sozialen-Medien 
                    direkt zu Ihrer WhatsApp-Kampagne.  
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures2;
