import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import PrivacySection from '../components/PrivacySection';
import FooterErp from '../components/Footer/FooterErp';

const Privacy = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Datenschutz" Pdescription=""/>
            <PrivacySection/>
            <FooterErp fClass="event_footer_area" />
        </div>
    )
}
export default Privacy;