import React, { useState } from "react";
import validator from 'validator';
import axios from 'axios';

const ChatBanner = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");

  const submitButtom = () => {
    if (validator.isEmail(email.trim())) {

      axios.post('https://digitale-kampagne.at/external/whatsappcampaign', { email, message})
      .then(function () {
        setEmailSent(true);
      })
      .catch(function () {
        setError("Es gibt ein Problem, die Nachricht konnte nicht gesendet werden! Bitte probieren Sie es später nochmal, oder kontaktieren Sie uns unter support@joobster.at");
      });

    } else {
      setError("Bitte überprüfen Sie die eingegebene Email-Adresse!");
    }
  }; 

  return (
    <section id="section_home" className="chat_banner_area">
      <img
        className="p_absoulte cloud"
        data-parallax='{"x": 0, "y": 150}'
        src={require('../../img/home-chat/cloud.png')}
        alt=""
      />
      <img
        className="p_absoulte left wow fadeInLeft"
        data-wow-delay="0.2s"
        src={require('../../img/home-chat/left_leaf.png')} 
        alt=""
      />
      <img
        className="p_absoulte right wow fadeInRight"
        data-wow-delay="0.3s"
        src={require('../../img/home-chat/right_leaf.png')}
        alt=""
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="chat_banner_content">
              <div className="c_tag wow fadeInUp">
                <img src={require("../../img/home-chat/live_chat.png")} alt="" /> Digitales Recruiting
              </div>
              <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                Digitale Kampagne mit <strong>Video</strong> und <strong>WhatsApp</strong>
              </h2>

              <p> 
                <strong>Der effektivste Weg, um mit digitalem Recruiting neue Mitarbeiter zu finden!</strong>
              </p>

              <p> 
                <strong>Sie wollen den Kampagnen-Builder testen, oder mehr über das Service erfahren?</strong> Wir freuen uns! Senden Sie uns eine Nachricht, wir kommen gerne auf Sie zu!
              </p>

              { emailSent ? (
                <div className="text-center pr_100 wow fadeInUp" data-wow-delay="0.4s">
                  <div className="form-group text_box">
                    <p>
                      <br/>
                      <strong>Vielen Dank für Ihr Interesse! Wir haben Ihre Nachricht erhalten, und 
                      werden Sie sobald als möglich kontaktieren!</strong>
                    </p>
                    <p>&hearts; Ihr joobster Team</p>
                  </div>
                </div>
              ) : (
                <div className="text-center pr_100 wow fadeInUp" data-wow-delay="0.4s">

                  { error.length > 0 && (
                    <div className="form-group text_box">
                      <p className="chat_banner_email_validator">{ error }</p>
                    </div>
                  )}

                  <div className="form-group text_box">
                    <input type="text" name="email" id="email" placeholder="Ihre Email-Adresse" onChange={event => setEmail(event.target.value)} />
                  </div>

                  <div className="form-group text_box">
                      <textarea name="message" id="message" cols="30" rows="2" maxLength="800" placeholder="Ihre Nachricht (800 Chars max.) . . ." onChange={event => setMessage(event.target.value)}></textarea>
                  </div>

                  <a href="/#" className="chat_btn btn_hover" onClick={submitButtom}>
                    Ja, ich möchte gerne mehr erfahren
                  </a>
                  
                </div>
              )}
                
            </div>
          </div>
          <div className="col-lg-4 offset-lg-2">
            <div className="chat_img">
              <img
                className="p_absoulte p_one"
                src={require('../../img/home-chat/panguin_img_one.png')}
                alt=""
              />
              <img
                className="p_absoulte p_two"
                src={require('../../img/home-chat/panguin_img_two.png')}
                alt=""
              />
              <img
                className="p_absoulte p_three"
                src={require('../../img/home-chat/panguin_img_three.png')}
                alt=""
              />
              <div className="round one"></div>
              <div className="round two"></div>
              <div className="round three"></div>
              <img
                className="wow fadeInUp"
                data-wow-delay="0.2s"
                src={require('../../img/home-chat/Messages.png')}
                alt="chat-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatBanner;
