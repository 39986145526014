import React from 'react';

const PrivacySection =()=>{
    return(
        <section className="faq_area bg_color sec_pad">
            <div className="container">
                <div className="row"> 
                    <div className="col-lg-12">
                        <div className="tab-content faq_content">

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Erklärung zur Informationspflicht (Datenschutzerklärung)</h3>
                                <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.</p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Kontakt mit uns</h3>
                                <p>Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Cookies</h3>
                                <p>Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.</p>
                                <p>Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
                                <p>Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.</p>
                                <p>Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>
                                <p>Ihre IP-Adresse wird erfasst, aber umgehend (z.B. durch Löschung der letzten 8 Bit) pseudonymisiert. Dadurch ist nur mehr eine grobe Lokalisierung möglich.</p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Ihre Rechte</h3>
                                <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.</p>
                            </div>

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Sie erreichen uns unter folgenden Kontaktdaten:</h3>
                                <p>joobster gmbh | Zentastraße 6/3/9 – 4061 Pasching – Austria | +43 677 623 604 03 | hello@joobster.at</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default PrivacySection;