import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const NotFound = () => (
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/error.png")} alt="" />

          <h2>Wir können die Seite leider nicht finden :(</h2>

          <p>
            Aber, vielleicht finden Sie auf der Hauptseite was Sie suchen.
          </p>
          
          <a href="/" className="about_btn btn_hover">
            Zurück zur Hauptseite <i className="arrow_right"></i>
          </a>
        </div>
      </div>
    </section>
    <FooterTwo FooterData={FooterData} />
  </div>
);
export default NotFound;
