import React, { Component } from "react";

class ChatFeatures3 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/admin.png")}
                  alt="" />
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <img src={require("../../img/home-chat/dashboard.png")} alt="" />
                <h2>
                  <span>Bewerber</span> verwalten
                </h2>
                <p>
                    Mit dem Verwaltungtool haben Sie alle Bewerbungen im Blick, und 
                    Zugriff auf alle zur Verfügung gestellten Unterlagen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures3;
